<template>
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <h1>Profile</h1>
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <div class="row">
            <div class="col-4" v-if="profileData">
              <table class="table">
                <tbody>
                  <tr>
                    <td>Username</td>
                    <td>:</td>
                    <td>
                      {{ profileData.username }}
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>:</td>
                    <td>
                      {{ profileData.email }}
                    </td>
                  </tr>
                  <tr>
                    <td>Roles</td>
                    <td>:</td>
                    <td>
                      <div v-if="profileData && profileData.roles">
                        <span
                          v-for="(role, index) in profileData.roles"
                          :key="index"
                        >
                          - {{ role.name }}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-6">
              <h5>Data Personil</h5>
              <button class="btn btn-primary" @click="syncAction">
                sync to personil data
              </button>

              <pre>
              {{ personData }}

              </pre>
            </div>
          </div>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import Service from "@/services/base.service";

export default {
  components: {},
  data() {
    return {
      profileData: {},
      personData: {},
    };
  },
  mounted() {
    this.fetchPersonData();
  },
  methods: {
    async fetchPersonData() {
      this.profileData = this.$store.state.auth.user;
      const { person_id } = this.profileData;
      if (person_id) {
        const BaseService = new Service("person");
        const { data } = await BaseService.getData(person_id);
        this.personData = data;
      }
    },
    async doSync(person_id) {
      const { id } = this.$store.state.auth.user;
      const BaseService = new Service("user");
      try {
        const { message } = await BaseService.updateData(id, { person_id });
        this.$swal.fire({
          title: "Berhasil! Silahkan logout kemudian login kembali",
          text: `${message}`,
          icon: "success",
        });
        // todo: refresh store user loggedin
      } catch (error) {
        this.$swal.fire({
          title: "Gagal!",
          text: `${error}`,
          icon: "error",
        });
      }
    },
    syncAction() {
      this.$swal
        .fire({
          title: "Masukan NIP / NRP anda",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Look up",
          showLoaderOnConfirm: true,
          preConfirm: async (nip) => {
            try {
              const BaseService = new Service("person");
              const { data } = await BaseService.getListData({
                nip_nrp: nip,
                limit: 1,
                offset: 0,
              });
              if (data.length < 1) {
                return this.$swal.showValidationMessage("data not found");
              }
              return data[0];
            } catch (error) {
              this.$swal.showValidationMessage(`Request failed: ${error}`);
            }
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal
              .fire({
                title: `#${result.value.nip_nrp} - ${result.value.nama_lengkap}`,
                text: "Tautkan akun pengguna dengan data personil ini?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ya, tautkan akun!",
              })
              .then((res) => {
                if (res.isConfirmed) {
                  this.doSync(result.value.id);
                }
              });
          }
        });
    },
  },
};
</script>
